//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-292:_876,_588,_276,_76,_204,_420,_492,_112;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-292')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-292', "_876,_588,_276,_76,_204,_420,_492,_112");
        }
      }catch (ex) {
        console.error(ex);
      }