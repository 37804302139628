import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import FacetValue from './facetValue.ts';

import type { ServerModel } from 'Modules/serverApi/types.ts';
import type { ArrayPredicate } from 'Core/types.ts';
import type { FacetValueFull } from './facetValue.ts';

export default class Facet {
  alwaysShowCount: number;
  field: string;
  isRanged: boolean;
  isSingleValue: boolean;
  isMultiselect: boolean;
  isTree: boolean;
  name: string;
  totalHits: number | null;
  ranges: string[] | null;
  selection: FacetValueFull[];
  treeSeparator: string | null;
  values: FacetValueFull[];

  _facetedValuesPredicates: ArrayPredicate<FacetValueFull>[] = [];
  _facet: ServerModel.FacetCategory;

  constructor(
    facet: ServerModel.FacetCategory,
    valuesFilter?: ArrayPredicate<FacetValueFull>,
    baseTreeLevel?: number,
  ) {
    this.alwaysShowCount = facet.AlwaysShowCount;
    this.field = facet.FieldName;
    this.isRanged = !!facet.IsRanged;
    this.isSingleValue = !!facet.IsSingleValue;
    this.isTree = !!facet.IsTree;
    this.name = facet.DisplayName || this.field;
    this.ranges = facet.Ranges || [];
    this.totalHits = facet.TotalHits;
    this.selection = FacetValue.createManyFromSearchResponse(facet.Selection || [], facet, baseTreeLevel);
    this.treeSeparator = facet.TreeSeparator;
    this.values = FacetValue.createManyFromSearchResponse(facet.Values || [], facet, baseTreeLevel);

    setFacetedValuesProcessors(this, valuesFilter);

    // temporary legacy compatibility fix
    this._facet = { ...facet, Values: facet.Values || [] };
  }

  get facetedSelection(): FacetValueFull[] {
    return getFaceted(this, this.selection);
  }

  get facetedValues(): FacetValueFull[] {
    return getFaceted(this, this.values);
  }
}

function getFaceted(self: Facet, values: FacetValueFull[]) {
  return values.filter((...args) => self._facetedValuesPredicates.every((predicate) => predicate(...args)));
}

function setFacetedValuesProcessors(self: Facet, customPredicate?: ArrayPredicate<FacetValueFull>) {
  self._facetedValuesPredicates = [
    facetsConfig.hideSelectedTreeValues && self.isTree && ((v: FacetValueFull) => !v.isSelected),
    self.isRanged && ((v: FacetValueFull) => v.isUnique || v.hitCount),
    customPredicate,
    (v: FacetValueFull) => Number(v.treeLevel) >= 0,
  ].filter(Boolean) as ArrayPredicate<FacetValueFull>[];
}
