import shopifyEnterpriseGenericDefaults from '../shopify-enterprise-generic/config.js';

const wheelsFields = shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['shopify-enterprise-generic'],
  ...shopifyEnterpriseGenericDefaults,
  SearchRequestDefaults: {
    ...shopifyEnterpriseGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyEnterpriseGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  Widgets: [
    ...shopifyEnterpriseGenericDefaults.Widgets,
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HomeWheelBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/wheels',
      selectFields: wheelsFields,
    },
    {
      name: 'HomeTireBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/tires',
      selectFields: tiresFields,
    },
  ],
};
