export default {
  BUTTON_PRIMARY_CLASS: 'btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',

  SEARCH_BOX_PLACEHOLDER: '{window.Convermax.config?.searchBoxPlaceholderText || "Search for products"}',
  SEARCH_BOX_CLASS: 'search',
  SEARCH_BOX_INPUT_CLASS: 'search__input w-full input',
  SEARCH_BOX_BUTTON_CLASS: 'search__submit text-current absolute focus-inset start',

  PRODUCT_REPEATER_CLASS:
    'grid mb-8 md:mb-12 grid-cols-2 small-cards-mobile sm:grid-cols-3 gap-x-theme gap-y-8 main-products-grid',
  LOAD_MORE_BUTTON_CLASS: 'btn btn--primary',
  FACET_INPUT_CLASS: 'input',

  RELATED_PRODUCT_LIST_CLASS:
    'slider slider__grid grid grid-flow-col auto-cols-2 small-cards-mobile md:auto-cols-3 lg:auto-cols-4 xl:auto-cols-5 small-cards-desktop gap-x-theme gap-y-8',
  RELATED_PRODUCT_TITLE_CLASS: 'h4 mt-10',

  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size header__cart-count" key="garage-size">{{size}}</span>',
  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',
};
